import { host } from './config';
import { SurfSession } from './models/surf-session';
import { addObjToQueryParams, baseFetch, PaginationParams } from './base-client';
export interface CreateSurfSessionParams {
  spot: {
    name: string;
    surfline_id: string;
  };
  surf_session: {
    session_start: number;
    session_end: number;
  };
}

export interface GetSurfSessionRequest {
  id: number;
}

export interface IndexSurfSessionsRequest extends PaginationParams {
  filters?: {
    by_spot_name: string;
  };
}

export interface IndexSurfSessionsResponse {
  collection: SurfSession[];
  meta: { total_count: number };
}

export const createSurfSession = async (params: CreateSurfSessionParams): Promise<SurfSession> => {
  const url = new URL(host());
  url.pathname = '/surf_sessions';

  return baseFetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify(params),
  }) as Promise<SurfSession>;
};

export const getSurfSession = async (params: GetSurfSessionRequest): Promise<SurfSession> => {
  const url = new URL(host());

  url.pathname = `/surf_sessions/${params.id}`;
  return baseFetch(url.toString(), {
    method: 'GET',
  }) as Promise<SurfSession>;
};

export const updateSurfSession = async (surfSession: SurfSession): Promise<SurfSession> => {
  const url = new URL(host());

  url.pathname = `/surf_sessions/${surfSession.id}`;
  return baseFetch(url.toString(), {
    method: 'PATCH',
    body: JSON.stringify(surfSession),
  }) as Promise<SurfSession>;
};

export const indexSurfSessions = async (params: IndexSurfSessionsRequest): Promise<IndexSurfSessionsResponse> => {
  const url = new URL(host());
  url.pathname = '/surf_sessions';
  url.searchParams.set('page', params.page.toString());
  url.searchParams.set('per_page', params.per_page.toString());

  if (params.filters) addObjToQueryParams(url, params.filters);

  return baseFetch(url.toString(), {
    method: 'GET',
  }) as Promise<IndexSurfSessionsResponse>;
};

export const destroySurfSession = async (params: { id: number }): Promise<void> => {
  const url = new URL(host());
  url.pathname = `/surf_sessions/${params.id}`;

  return baseFetch(url.toString(), {
    method: 'DELETE',
  }) as Promise<void>;
};
