import { v4 as uuidv4 } from 'uuid';
import { host } from '../api/config';
import { errorForResponse } from '../api/errors';

class Auth {
  static isLoggedIn() {
    return this.authToken != null;
  }

  static get anonToken() {
    let anonToken = localStorage.getItem('anonToken');
    if (anonToken != null) return anonToken;

    anonToken = uuidv4();
    localStorage.setItem('anonToken', anonToken);

    return anonToken;
  }

  static get authToken(): string | null {
    return localStorage.getItem('authToken');
  }

  static set authToken(token: string | null) {
    if (token == null) {
      this.clearAuthToken();
      return;
    }

    localStorage.setItem('authToken', token);
  }

  static clearAuthToken() {
    localStorage.removeItem('authToken');
  }

  static async login(user: { email: string; password: string }): Promise<User> {
    const url = new URL(host());
    url.pathname = '/login';

    const response = await fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify({ user }),
      headers: this.requestHeaders(),
    });

    return this.handleAuthResponse(response);
  }

  static async signup(user: { email: string; password: string }): Promise<User> {
    const url = new URL(host());
    url.pathname = '/signup';

    const response = await fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify({ user }),
      headers: this.requestHeaders(),
    });

    return this.handleAuthResponse(response);
  }

  static async logout() {
    const url = new URL(host());
    url.pathname = '/logout';

    await fetch(url.toString(), {
      method: 'DELETE',
      headers: this.requestHeaders(),
    });

    this.clearAuthToken();
  }

  private static async handleAuthResponse(response: Response) {
    let data = {};
    try {
      data = await response.json();
    } catch (e) {}

    if (!response.ok) {
      throw errorForResponse(response, data);
    }

    const jwt = response.headers.get('Authorization');
    if (jwt) {
      this.authToken = jwt;
    }

    return data as User;
  }

  private static requestHeaders() {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (this.authToken) {
      headers['Authorization'] = this.authToken;
    }

    if (this.anonToken) {
      headers['X-Anon-Token'] = this.anonToken;
    }

    return headers;
  }
}

export default Auth;
