import { host } from './config';
import { baseFetch } from './base-client';

export interface SearchSpotRequest {
  name: string;
}

export interface SearchSpotResult {
  id: string;
  lat: number;
  lon: number;
  name: string;
}

export const searchSpots = async ({ name }: SearchSpotRequest): Promise<SearchSpotResult[]> => {
  const url = new URL(host());
  url.pathname = '/spots/search';
  url.searchParams.set('name', name);

  return baseFetch(url.toString()) as Promise<SearchSpotResult[]>;
};
