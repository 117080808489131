import { useState, useEffect } from 'react';
import { BiBookAdd } from 'react-icons/bi';
import { MdOutlineAccountCircle } from 'react-icons/md';
import Auth from '../../lib/auth';
import ThemeToggle from '../common/ThemeToggle';

const NavBar = () => {
  const [isLoggedin, setIsLoggedIn] = useState(Auth.isLoggedIn());
  const doLogOut = async () => {
    await Auth.logout();
    window.location.href = '/login';
  };

  const handleAuthChange = (event: StorageEvent) => {
    setIsLoggedIn(Auth.isLoggedIn());
  };

  useEffect(() => {
    window.addEventListener('storage', handleAuthChange);
  }, []);

  return (
    <div className="navbar bg-base-100">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </div>
          <ul tabIndex={0} className="menu dropdown-content mt-3 z-[100] p-2 shadow bg-base-300 rounded-box w-52">
            <li>
              {!Auth.isLoggedIn() && <a href="/login">Log In / Sign Up</a>}
              <a href="/surf_sessions">View Sessions</a>
              <a href="/">Log Session</a>
            </li>
          </ul>
        </div>
      </div>
      <a href="/surf_sessions" className="btn btn-ghost text-xl hidden lg:flex">
        surf 🏄 base
      </a>
      <div className="navbar-end gap-4 mr-2">
        <a href="/" className="btn">
          <BiBookAdd size={20} />
          Log Session
        </a>
        <ThemeToggle />
        {isLoggedin ? (
          <div className="dropdown dropdown-end">
            <MdOutlineAccountCircle tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar btn-sm" />
            <ul tabIndex={0} className="menu dropdown-content mt-3 z-[100] p-2 shadow bg-base-300 rounded-box w-52">
              <li>
                <button onClick={doLogOut}>Logout</button>
              </li>
            </ul>
          </div>
        ) : (
          <button className="btn btn-primary" onClick={() => (window.location.href = '/login')}>
            Log In
          </button>
        )}
      </div>
    </div>
  );
};

export default NavBar;
