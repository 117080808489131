import { SurfSession } from '../models/surf-session';
import dateFormat from 'dateformat';

class SurfSessionDecorator {
  constructor(private readonly surfSession: SurfSession) {}

  get shortDate() {
    // if yesterday or today return "Yesterday" or "Today"
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (this.surfSession.session_start * 1000 > today.getTime()) {
      return 'Today';
    } else if (this.surfSession.session_start * 1000 > yesterday.getTime()) {
      return 'Yesterday';
    } else {
      return dateFormat(this.surfSession.session_start * 1000, 'mmm dS');
    }
  }

  get date() {
    return dateFormat(this.surfSession.session_start * 1000, 'dddd, mmmm dS, yyyy');
  }

  get time() {
    const startTimeText = dateFormat(this.surfSession.session_start * 1000, 'h:MM TT');
    const endTimeText = dateFormat(this.surfSession.session_end * 1000, 'h:MM TT');
    return `${startTimeText} - ${endTimeText}`;
  }

  get heading() {
    return this.surfSession.title || this.surfSession.spot.name;
  }
}

export default SurfSessionDecorator;
