import { Conditions } from '../models/conditions';

export class SurfDecorator {
  constructor(
    private readonly startConditions: Conditions,
    private readonly endConditions: Conditions,
  ) {}

  get chipColor() {
    const mostBlue = 10;
    const ratio = Math.min(1, this.maxHeight / mostBlue);

    // return a multple of 100 between 400 and 1000 based on the ratio
    let intensity = 200 + Math.floor(ratio * 8) * 100;
    if (intensity >= 1000) {
      intensity = 950;
    }

    return `bg-blue-${intensity}`;
  }

  get heightRange() {
    const plus =
      this.startConditions.surf.max > this.endConditions.surf.max
        ? this.startConditions.surf.plus
        : this.endConditions.surf.plus;
    return `${this.minHeight} - ${this.maxHeight}${this.startConditions.surf.unit.toLowerCase()}${plus ? '+' : ''}`;
  }

  private get maxHeight() {
    return Math.max(this.startConditions.surf.max, this.endConditions.surf.max);
  }

  private get minHeight() {
    return Math.min(this.startConditions.surf.min, this.endConditions.surf.min);
  }
}
