import React from 'react';
import { TideDecorator } from '../../api/decorators/tide-decorator';
import { Conditions } from '../../api/models/conditions';
import ConditionsCard from './ConditionsCard';

interface Props {
  conditionStart: Conditions;
  conditionEnd: Conditions;
}
const TideConditions: React.FC<Props> = ({ conditionStart, conditionEnd }) => {
  const decorator = new TideDecorator(conditionStart, conditionEnd);

  if (!decorator.hasTide) return null;

  const type = conditionStart.tide.type;

  return (
    <ConditionsCard title="Tide 🌙" header={type}>
      <div className="text-md">{decorator.direction}</div>
      <div className="text-md">{decorator.heightRange}</div>
    </ConditionsCard>
  );
};

export default TideConditions;
