export const config = {
  development: {
    host: 'http://localhost:3001',
  },
  test: {
    host: 'http://localhost:3001',
  },
  staging: {
    host: '',
  },
  production: {
    host: 'https://surfbase-api-98be17baa448.herokuapp.com',
  },
};

const environment: string = process.env.REACT_APP_ENV || 'development';

export const host = () => {
  return config[environment as keyof typeof config].host;
};
