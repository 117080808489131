import { Conditions } from '../models/conditions';
import { degreeToCompass } from '../../lib/display';

export class WindDecorator {
  constructor(
    private readonly startConditions: Conditions,
    private readonly endConditions: Conditions,
  ) {}

  GOOD_WIND = 5;
  MID_WIND = 10;
  BAD_WIND = 15;

  get chipColor() {
    if (
      this.startConditions.wind.direction_type === 'Offshore' &&
      this.endConditions.wind.direction_type === 'Offshore'
    ) {
      return 'bg-green-600';
    }

    if (this.maxSpeed < this.GOOD_WIND) {
      return 'bg-green-600';
    } else if (this.maxSpeed < this.MID_WIND) {
      return 'bg-yellow-600';
    } else {
      return 'bg-red-700';
    }
  }

  get summaryText() {
    const startSpeed = Math.round(this.startConditions.wind.speed);
    const endSpeed = Math.round(this.endConditions.wind.speed);

    const speedText = Math.max(startSpeed, endSpeed) + this.startConditions.wind.unit.toLowerCase();

    const startDirectionType = this.shortenWindDirectionType(this.startConditions.wind.direction_type);
    const endDirectionType = this.shortenWindDirectionType(this.endConditions.wind.direction_type);

    const directionTypeText =
      startDirectionType === endDirectionType ? `${startDirectionType}` : `${startDirectionType}/${endDirectionType}`;

    return `${speedText} ${directionTypeText}`;
  }

  get speedRange() {
    return this.startConditions.wind.speed === this.endConditions.wind.speed
      ? `${Math.round(this.startConditions.wind.speed)}${this.startConditions.wind.unit.toLowerCase()}`
      : `${Math.round(this.startConditions.wind.speed)} to ${Math.round(this.endConditions.wind.speed)}${this.startConditions.wind.unit.toLowerCase()}`;
  }

  get gustRange() {
    return this.startConditions.wind.gust === this.endConditions.wind.gust
      ? `${Math.round(this.startConditions.wind.gust)}${this.startConditions.wind.unit.toLowerCase()}`
      : `${Math.round(this.startConditions.wind.gust)} to ${Math.round(this.endConditions.wind.gust)}${this.startConditions.wind.unit.toLowerCase()}`;
  }

  get typeRange() {
    return this.startConditions.wind.direction_type === this.endConditions.wind.direction_type
      ? `${this.startConditions.wind.direction_type}`
      : `${this.startConditions.wind.direction_type}/${this.endConditions.wind.direction_type}`;
  }

  get directionRange() {
    return `${degreeToCompass(this.startConditions.wind.direction)} to ${degreeToCompass(this.endConditions.wind.direction)}`;
  }

  private shortenWindDirectionType(directionType: string) {
    if (directionType === 'Onshore') {
      return 'ON';
    } else if (directionType === 'Offshore') {
      return 'OFF';
    } else if (directionType === 'Cross-shore') {
      return 'X';
    } else {
      return '';
    }
  }

  private get minSpeed() {
    return Math.round(Math.min(this.startConditions.wind.speed, this.endConditions.wind.speed));
  }

  private get maxSpeed() {
    return Math.round(Math.max(this.startConditions.wind.speed, this.endConditions.wind.speed));
  }
}
