import React from 'react';

interface Props {
  disabled?: boolean;
  value: number | null;
  onChange?: (value: number) => void;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Rating: React.FC<Props> = ({ value, onChange, disabled, size }) => {
  const handleChange = (newValue: number) => {
    if (onChange) onChange(newValue);
  };

  return (
    <div>
      {/* This is a hack to get the classes to load properly. */}
      <div className="rating-xs" />
      <div className="rating-sm" />
      <div className="rating-md" />
      <div className="rating-lg" />
      <div className={`rating ${size ? 'rating-' + size : ''}`}>
        <input
          disabled={disabled}
          type="radio"
          id="no-rating"
          className="mask mask-star"
          checked={Number(value) === 0}
          onChange={() => handleChange(0)}
          style={{ display: 'none' }} // This allows the rating to initialize with 0
        />

        {[1, 2, 3, 4, 5].map((index) => (
          <input
            disabled={disabled}
            key={index}
            type="radio"
            className="mask mask-star"
            checked={Number(value) === index}
            onChange={() => handleChange(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Rating;
