import debounce from 'debounce-promise';
import { useMemo, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { LoaderFunction, useLoaderData } from 'react-router-dom';
import { indexSurfSessions, IndexSurfSessionsRequest, IndexSurfSessionsResponse } from '../../api/surf-sessions-client';
import LogInCta from '../../components/common/LoginCta';
import SurfSessionListItem from '../../components/surf-sessions/SurfSessionListItem';
import Auth from '../../lib/auth';

export const loader: LoaderFunction = async () => {
  return await indexSurfSessions({ page: 1, per_page: 10 });
};

const SurfSessionsList = () => {
  const [pagination, setPagination] = useState({ page: 1, per_page: 10 } as { page: number; per_page: number });
  const [surfSessionsResponse, setSurfSessionsResponse] = useState(useLoaderData() as IndexSurfSessionsResponse);
  const [surfSessions, setSurfSessions] = useState(surfSessionsResponse.collection);
  const [spotNameFilter, setSpotNameFilter] = useState('');

  const loadMore = async () => {
    const params = {
      page: pagination.page + 1,
      per_page: pagination.per_page,
      filters: { by_spot_name: spotNameFilter },
    };

    const newSurfSessionsResponse = await indexSurfSessions(params);

    setSurfSessionsResponse(newSurfSessionsResponse);
    setSurfSessions([...surfSessions, ...newSurfSessionsResponse.collection]);
    setPagination({ page: params.page, per_page: params.per_page });
  };

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSpotNameFilter(value);

    const params: IndexSurfSessionsRequest = {
      page: 1,
      per_page: 10,
      filters: { by_spot_name: value },
    };

    const updateSurfSessions = async (params: IndexSurfSessionsRequest) => {
      const newSurfSessionsResponse = await indexSurfSessions(params);
      setSurfSessionsResponse(newSurfSessionsResponse);
      setSurfSessions(newSurfSessionsResponse.collection);
      setPagination({ page: params.page, per_page: params.per_page });
    };

    debounce(() => updateSurfSessions(params), 300)();
  };

  const hasMore = useMemo(() => {
    return surfSessionsResponse.meta.total_count > surfSessions.length;
  }, [surfSessionsResponse, surfSessions]);

  return (
    <div>
      {!Auth.isLoggedIn() && surfSessions && surfSessions.length > 0 && <LogInCta />}
      <div className="mx-5 py-5">
        <div aria-label="controls">
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              className="grow"
              placeholder="Filter by spot name"
              value={spotNameFilter}
              onChange={handleSearchChange}
            />
            <FaSearch className="text-neutral-content/50" />
          </label>
        </div>

        <div className="divider"></div>

        {surfSessions.length === 0 ? (
          <div className="relative text-center top-[30%] text-gray-500">
            Nothing here.{' '}
            <a href="/" className="underline">
              Log a surf
            </a>
          </div>
        ) : (
          <div className="space-y-5">
            {surfSessions.map((surfSession, index) => {
              return <SurfSessionListItem surfSession={surfSession} key={index} />;
            })}

            <div className="text-center">
              {hasMore && (
                <button onClick={loadMore} className="btn btn-neutral">
                  Load More
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurfSessionsList;
