import React, { ChangeEventHandler, useState } from 'react';

import { LoaderFunction, useLoaderData, useNavigate } from 'react-router-dom';
import { SurfSession } from '../../api/models/surf-session';
import { destroySurfSession, getSurfSession, updateSurfSession } from '../../api/surf-sessions-client';
import SurfConditions from '../../components/conditions/SurfConditions';
import SwellConditions from '../../components/conditions/SwellConditions';
import TideConditions from '../../components/conditions/TideConditions';
import WindConditions from '../../components/conditions/WindConditions';
import dateFormat from 'dateformat';
import { FaTrash } from 'react-icons/fa';
import Rating from '../../components/common/Rating';

interface Params {
  id: string;
}

export const loader: LoaderFunction<Params> = async ({ params }) => {
  if (!params.id) {
    throw new Error('Invalid surf session ID');
  }

  const surfSession = await getSurfSession({ id: Number(params.id) });
  return surfSession;
};

// TODO: handle api errors
const ViewSurfSession = () => {
  const [surfSession, setSurfSession] = useState(useLoaderData() as SurfSession);
  const [isDirty, setIsDirty] = React.useState(false);
  const [submitText, setSubmitText] = React.useState('Save');
  const [confirmingDelete, setConfirmingDelete] = React.useState(false);

  const dateText = dateFormat(surfSession.session_start * 1000, 'dddd, mmmm dS, yyyy');
  const startTimeText = dateFormat(surfSession.session_start * 1000, 'h:MM TT');
  const endTimeText = dateFormat(surfSession.session_end * 1000, 'h:MM TT');

  const put = async (newSurfSession: SurfSession) => {
    setSurfSession(newSurfSession);
    await updateSurfSession(newSurfSession);
  };

  const navigate = useNavigate();

  const onRatingChange = (newValue: number) => {
    setSurfSession({ ...surfSession, rating: newValue });
    setIsDirty(true);
    setConfirmingDelete(false);
    setSubmitText('Save');
  };

  const onNotesChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setSurfSession({ ...surfSession, notes: event.target.value });
    setIsDirty(true);
    setConfirmingDelete(false);
    setSubmitText('Save');
  };

  const submit = async () => {
    await put(surfSession);
    setIsDirty(false);
    setSubmitText('Saved');
    navigate('/surf_sessions');
  };

  const destroy = async () => {
    await destroySurfSession({ id: surfSession.id });
    navigate('/surf_sessions');
  };
  return (
    <div className="flex flex-col items-center gap-5 h-5/6">
      <div>
        <h1 className="text-xl font-semibold text-center">{surfSession.title || surfSession.spot.name}</h1>
        <h2 className="text-gray-400 text-sm text-center">{dateText}</h2>
        <h2 className="text-gray-400 text-sm text-center">
          {startTimeText} - {endTimeText}
        </h2>
      </div>

      <div aria-label="conditions" className="flex gap-3 justify-center flex-wrap">
        <SwellConditions conditionStart={surfSession.start_conditions} conditionEnd={surfSession.end_conditions} />
        <SurfConditions conditionStart={surfSession.start_conditions} conditionEnd={surfSession.end_conditions} />
        <WindConditions conditionStart={surfSession.start_conditions} conditionEnd={surfSession.end_conditions} />
        <TideConditions conditionStart={surfSession.start_conditions} conditionEnd={surfSession.end_conditions} />
      </div>

      <div aria-label="form" className="flex flex-col items-center gap-5 w-full h-full">
        <Rating value={surfSession.rating || 0} onChange={onRatingChange} />
        <textarea
          className="textarea textarea-bordered w-4/5 max-h-48 flex-grow max-w-3xl"
          defaultValue={surfSession.notes || ''}
          onChange={onNotesChange}
          placeholder="Notes"
        ></textarea>
        <button className="btn btn-wide btn-primary" disabled={!isDirty} type="submit" onClick={submit}>
          {submitText}
        </button>
        {!confirmingDelete ? (
          <button className="btn btn-ghost" onClick={() => setConfirmingDelete(true)}>
            <FaTrash />
          </button>
        ) : (
          <span>
            <button className="btn text-error transition-all mx-1" onClick={destroy}>
              Delete
            </button>

            <button className="btn transition-all mx-1" onClick={() => setConfirmingDelete(false)}>
              Cancel
            </button>
          </span>
        )}
      </div>
    </div>
  );
};

export default ViewSurfSession;
