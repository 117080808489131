import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SurfDecorator } from '../../api/decorators/surf-decorator';
import SurfSessionDecorator from '../../api/decorators/surf-session-decorator';
import { SwellDecorator } from '../../api/decorators/swell-decorator';
import { TideDecorator } from '../../api/decorators/tide-decorator';
import { WindDecorator } from '../../api/decorators/wind-decorator';
import { SurfSession } from '../../api/models/surf-session';
import Rating from '../common/Rating';

interface Props {
  surfSession: SurfSession;
}

const SurfSessionListItem: React.FC<Props> = ({ surfSession }) => {
  const surfSessionDecorator = new SurfSessionDecorator(surfSession);
  const windDecorator = new WindDecorator(surfSession.start_conditions, surfSession.end_conditions);
  const tideDecorator = new TideDecorator(surfSession.start_conditions, surfSession.end_conditions);
  const swellDecorator = new SwellDecorator(surfSession.start_conditions, surfSession.end_conditions);
  const surfDecorator = new SurfDecorator(surfSession.start_conditions, surfSession.end_conditions);

  const navigate = useNavigate();

  const navigateToSurfSession = () => {
    navigate(`/surf_sessions/${surfSession.id}`);
  };

  return (
    <div className="collapse rounded-md bg-base-200 shadow-lg">
      <input type="checkbox" />

      <div className="collapse-title pr-4">
        <div className="flex">
          <div className="w-full">{surfSessionDecorator.heading}</div>
          <div className="text-xs text-slate-400 text-nowrap">{surfSessionDecorator.shortDate}</div>
        </div>
        <div aria-label="badges" className="my-2 flex flex-wrap">
          <Chip color={surfDecorator.chipColor} label={surfDecorator.heightRange} />
          <Chip color={windDecorator.chipColor} label={windDecorator.summaryText} />
          <Chip
            color={swellDecorator.chipColor}
            label={`${swellDecorator.primarySwellText}, ${swellDecorator.primarySwellDegrees}`}
          />
          {tideDecorator.hasTide && (
            <Chip
              color={tideDecorator.chipColor}
              label={`${surfSession.start_conditions.tide.height}${surfSession.start_conditions.tide.unit.toLowerCase()} ${tideDecorator.direction}`}
            />
          )}
        </div>
        <div className="flex text-xs text-slate-400">
          <Rating value={surfSession.rating || 0} disabled size="xs" />
        </div>
      </div>
      <div className="collapse-content text-base-content/80 text-xs flex">
        <p className="w-full">{surfSession.notes || 'No notes.'}</p>
        <button className="text-xl font-semibold text-primary text-nowrap" onClick={navigateToSurfSession}>
          {'->'}
        </button>
      </div>
    </div>
  );
};

const Chip = ({ label, color }: { label: string; color?: string }) => {
  return <span className={`${color ? color : 'bg-green-500'} rounded-xl text-white text-xs px-2 py-1`}>{label}</span>;
};

export default SurfSessionListItem;
