import { useState } from 'react';
import { FaLifeRing } from 'react-icons/fa';

const LogInCta = () => {
  const [dismissed, setDismissed] = useState(sessionStorage.getItem('login-cta-dismissed') || false);

  const handleDismiss = () => {
    setDismissed(true);
    sessionStorage.setItem('login-cta-dismissed', 'true');
  };

  return (
    <div role="alert" className={`flex items-center gap-2 bg-base-300 p-5 rounded-lg ${dismissed ? 'hidden' : ''}`}>
      <FaLifeRing className="h-6 w-6 shrink-0" />
      <div className="text-nowrap">
        Don't lose your logs!
        <a className="text-primary" href="login">
          {' '}
          Log in{' '}
        </a>
        or
        <a className="text-primary" href="login">
          {' '}
          Sign up{' '}
        </a>
        to keep your logs safe and sync them across devices.
      </div>

      <button onClick={handleDismiss} className="ml-auto text-slate-500">
        ✕
      </button>
    </div>
  );
};

export default LogInCta;
