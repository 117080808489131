interface Props {
  message: string;
  className?: string;
  onDismiss?: () => void;
}

const ErrorAlert: React.FC<Props> = ({ message, className, onDismiss }) => {
  const handleDismissClick = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <div role="alert" className={`flex gap-2 bg-base-300 p-5 rounded-lg ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" className="stroke-error shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span className="text-error text-nowrap">{message}</span>
      <button onClick={handleDismissClick} className="ml-auto text-slate-500">
        ✕
      </button>
    </div>
  );
};

export default ErrorAlert;
