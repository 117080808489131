import React, { useState } from 'react';

import keyIcon from '../svg/key-icon';
import emailIcon from '../svg/email-icon';
import Auth from '../lib/auth';
import ErrorAlert from '../components/common/ErrorAlert';
import { UnauthorizedError } from '../api/errors';

//TODO: Validate inputs, handle errors
function LogIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleError = (e: any) => {
    if (e instanceof UnauthorizedError) {
      setError('Invalid email or password');
    } else {
      const message = e.message;

      message ? setError(message) : setError('An unknown error occurred.');
    }
  };

  const doSignUp = async () => {
    try {
      await Auth.signup({ email, password });
      window.location.href = '/surf_sessions';
    } catch (e) {
      handleError(e);
    }
  };

  const doLogIn = async () => {
    try {
      await Auth.login({ email, password });
      window.location.href = '/surf_sessions';
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <div>
      <ErrorAlert className={error ? '' : 'invisible'} onDismiss={() => setError(null)} message={error || 'null'} />
      <div className="flex flex-col items-center gap-2 pt-20 h-full-with-navbar">
        <label className="input input-bordered flex items-center gap-2 w-72">
          {emailIcon}
          <input
            className="grow"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            type="text"
            value={email}
          />
        </label>

        <label className="input input-bordered flex items-center gap-2 w-72">
          {keyIcon}
          <input
            className="grow"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
            value={password}
          />
        </label>

        <button onClick={doLogIn} className="btn w-72">
          Log In
        </button>

        <a href="/forgot_password" className="text-slate-400 text-sm py-3">
          Forgot password?
        </a>

        <div className="w-72">
          <div className="divider">OR</div>
        </div>

        <button onClick={doSignUp} className="btn btn-outline w-72">
          Create new account
        </button>
      </div>
    </div>
  );
}

export default LogIn;
