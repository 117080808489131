import { FaArrowUpLong } from 'react-icons/fa6';

interface Props {
  degrees: number;
  [key: string]: any;
}
const DirectionArrow: React.FC<Props> = ({ degrees, ...props }) => {
  const rotationStyle = {
    transform: `rotate(${degrees + 180}deg)`,
  };

  return <FaArrowUpLong style={rotationStyle} {...props} />;
};

export default DirectionArrow;
