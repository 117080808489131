import React, { useState, useMemo, useRef, useEffect } from 'react';
import { SearchSpotResult, searchSpots } from '../api/spot-client';
import debounce from 'debounce-promise';

interface SpotSearchProps {
  callback?: (result: SearchSpotResult) => void;
}

const SpotSearch: React.FC<SpotSearchProps> = ({ callback }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [results, setResults] = useState<SearchSpotResult[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const loadSpots = async (input: string) => {
    if (!input) {
      setResults([]);
      return [];
    }
    const res = await searchSpots({ name: input });
    setResults(res);
    return res;
  };

  const debouncedLoadSpots = useMemo(() => debounce(loadSpots, 300), []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    debouncedLoadSpots(event.target.value);
  };

  const handleSelect = (id: string) => {
    const result = results.find((result) => result.id === id);
    if (result && callback) {
      callback(result);
    }
    setIsOpen(false);
    setInputValue(result ? result.name : '');
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className="relative w-full">
      <input
        type="text"
        className="input input-bordered w-full text-lg"
        value={inputValue}
        onChange={handleChange}
        onFocus={() => setIsOpen(true)}
        placeholder="Search for a surf spot"
      />
      {isOpen && (
        <ul className="menu bg-base-200 w-full rounded-box mt-1 shadow-lg absolute z-10 text-lg">
          {results.length > 0 ? (
            results.map((result) => (
              <li
                onClick={() => handleSelect(result.id)}
                key={result.id}
                className="menu-item hover:cursor-pointer hover:bg-neutral-content/20 px-2 py-1 rounded"
              >
                {result.name}
              </li>
            ))
          ) : (
            <li className="menu-item px-2 py-1">No results.</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SpotSearch;
