import Auth from '../lib/auth';
import { errorForResponse } from './errors';

export interface PaginationParams {
  page: number;
  per_page: number;
}

export const baseFetch = async (input: string | URL | globalThis.Request, options?: RequestInit): Promise<unknown> => {
  const finalOptions = options || {};
  const defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
  };

  defaultHeaders['X-Anon-Token'] = Auth.anonToken;

  const authHeaderValue = Auth.authToken;
  if (authHeaderValue != null) {
    defaultHeaders['Authorization'] = authHeaderValue;
  }

  finalOptions.headers = { ...finalOptions.headers, ...defaultHeaders };

  const response = await fetch(input, options);

  const data = response.status === 204 ? null : await response.json();

  if (response.ok) {
    return data;
  } else if (response.status === 401) {
    Auth.clearAuthToken();
    window.location.href = '/login';
  } else {
    throw errorForResponse(response, data);
  }
};

export const addObjToQueryParams = (url: URL, obj: Record<string, string | number | boolean | null | undefined>) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value != null) {
      url.searchParams.set(key, value.toString());
    }
  }
};
