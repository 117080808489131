import React from 'react';
import { Conditions, Swell } from '../../api/models/conditions';
import { degreeToCompass, round10 } from '../../lib/display';
import { getTop3Swells } from '../../lib/forecast-utils';
import ConditionsCard from './ConditionsCard';
import DirectionArrow from '../common/DirectionArrow';

interface Props {
  conditionStart: Conditions;
  conditionEnd: Conditions;
}

const SwellConditions: React.FC<Props> = ({ conditionStart, conditionEnd }) => {
  const swellDirectionText = (swell: Swell) => {
    return `${degreeToCompass(swell.direction)}`;
  };
  const swellText = (swell: Swell) => {
    return `${round10(swell.height)}${swell.unit.toLowerCase()}, ${swell.period}s`;
  };

  return (
    <ConditionsCard title="Swell 🌀">
      {getTop3Swells(conditionStart.swell.swells).map((swell, index) => (
        <div className={`text-md ${index === 0 ? 'font-semibold' : ''}`} key={index}>
          <span className="inline-block">
            {swellText(swell)} • {swellDirectionText(swell)} {Math.round(swell.direction)}°
          </span>
          <DirectionArrow className="ml-1 inline-block" degrees={swell.direction} />
        </div>
      ))}
    </ConditionsCard>
  );
};

export default SwellConditions;
