import React from 'react';
import { WindDecorator } from '../../api/decorators/wind-decorator';
import { Conditions } from '../../api/models/conditions';
import ConditionsCard from './ConditionsCard';

interface Props {
  conditionStart: Conditions;
  conditionEnd: Conditions;
}

const WindConditions: React.FC<Props> = ({ conditionStart, conditionEnd }) => {
  const decorator = new WindDecorator(conditionStart, conditionEnd);

  return (
    <ConditionsCard title="Wind 💨" header={decorator.speedRange}>
      <div className="text-md">{decorator.typeRange}</div>
      <div className="text-md">{decorator.gustRange} gust</div>
    </ConditionsCard>
  );
};

export default WindConditions;
