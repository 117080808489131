export const round10 = (num: number) => Math.round(num * 10) / 10;

export const degreeToCompass = (degree: number) => {
  if (degree < 0 || degree > 360) {
    return 'Invalid degree';
  }

  const directions = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  const index = Math.floor((degree + 11.25) / 22.5) % 16;
  return directions[index];
};
