import { round10 } from '../../lib/display';
import { getTop3Swells } from '../../lib/forecast-utils';
import { Conditions } from '../models/conditions';

export class SwellDecorator {
  constructor(
    private readonly startConditions: Conditions,
    private readonly endConditions: Conditions,
  ) {}

  get chipColor() {
    const mostBlack = 20;
    const ratio = Math.min(1, this.orderedSwells[0].period / mostBlack);
    // get a multiple of 100 between 400 and 1000 based on the ratio
    const intensity = 200 + Math.floor(ratio * 6) * 100;
    return `bg-stone-${intensity}`;
  }

  get primarySwellText() {
    const primarySwell = this.orderedSwells[0];
    return primarySwell
      ? `${round10(primarySwell.height)}${primarySwell.unit.toLowerCase()}, ${primarySwell.period}s`
      : '';
  }

  get primarySwellDegrees() {
    const primarySwell = this.orderedSwells[0];
    return primarySwell ? Math.round(primarySwell.direction) + '°' : 0;
  }

  get orderedSwells() {
    return getTop3Swells(this.startConditions.swell.swells); // Surfline already orders it for me
  }
}
