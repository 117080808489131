import React from 'react';
import { SurfDecorator } from '../../api/decorators/surf-decorator';
import { Conditions } from '../../api/models/conditions';
import ConditionsCard from './ConditionsCard';

interface Props {
  conditionStart: Conditions;
  conditionEnd: Conditions;
}

const SurfConditions: React.FC<Props> = ({ conditionStart, conditionEnd }) => {
  const decorator = new SurfDecorator(conditionStart, conditionEnd);
  return (
    <ConditionsCard title="Wave 🌊" header={decorator.heightRange}>
      <div className="text-md">{conditionStart.surf.human_relation}</div>
    </ConditionsCard>
  );
};

export default SurfConditions;
