import './TimeSelect.css';
import { TimeField, DateInput, DateSegment, TimeValue } from 'react-aria-components';

interface TimeSelectProps {
  callback?: (results: TimeValue) => void;
}
const TimeSelect = ({ callback }: TimeSelectProps) => {
  return (
    <TimeField aria-label="time-field" onChange={callback}>
      <DateInput className="flex">{(segment) => <DateSegment segment={segment} />}</DateInput>
    </TimeField>
  );
};

export default TimeSelect;
