import { useEffect, useState } from 'react';
import { FiSun, FiMoon } from 'react-icons/fi';

const LIGHT_THEME = 'light';
const DARK_THEME = 'dark';

const ThemeToggle = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || DARK_THEME);

  useEffect(() => {
    localStorage.setItem('theme', theme);
    const htmlElement = document.querySelector('html');

    if (!htmlElement) {
      throw new Error('No html element found');
    }

    htmlElement.setAttribute('data-theme', theme);
  }, [theme]);

  // update state on toggle
  const handleToggle = (e: any) => {
    if (e.target.checked) {
      setTheme(DARK_THEME);
    } else {
      setTheme(LIGHT_THEME);
    }
  };

  return (
    <button className="btn btn-circle btn-ghost btn-sm">
      <label className="swap swap-rotate">
        <input type="checkbox" onChange={handleToggle} checked={theme === LIGHT_THEME ? false : true} />
        <FiSun size={24} className="swap-on" />
        <FiMoon size={24} className="swap-off" />
      </label>
    </button>
  );
};

export default ThemeToggle;
