import { Swell } from '../api/models/conditions';

export const getTop3Swells = function getTop3Swells(swells: Swell[]) {
  if (
    swells.every(function (s) {
      return s.impact !== undefined;
    })
  ) {
    return [...swells]
      .sort(function (a, b) {
        return b.impact !== a.impact ? b.impact - a.impact : swellImpact(b) - swellImpact(a);
      })
      .slice(0, 3);
  } else {
    return [...swells]
      .sort(function (a, b) {
        return swellImpact(b) - swellImpact(a);
      })
      .slice(0, 3);
  }
};

const swellImpact = (swell: Swell) => {
  return swell.period && swell.height ? swell.height * (0.1 * swell.period) : 0;
};
