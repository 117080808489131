export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AuthorizationError';
  }
}

export class ForbiddenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

export class InternalServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InternalServerError';
  }
}

export class ValidationError extends Error {
  errors: string[];

  constructor(errors: string[]) {
    super(errors[0]);
    this.errors = errors;
    this.name = 'ValidationError';
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

export const errorForResponse = (response: Response, data: any): Error => {
  switch (response.status) {
    case 401:
      return new UnauthorizedError(data.error);
    case 500:
      return new InternalServerError(data.error);
    case 422:
      return new ValidationError(data.errors);
    case 403:
      return new ForbiddenError(data.error);
    case 404:
      return new NotFoundError(data.error);
    default:
      return new Error('Unknown Error');
  }
};
