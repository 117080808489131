import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NavBar from './components/nav/Navbar';
import LogIn from './routes/LogIn';
import NewSurfSession from './routes/surf-sessions/NewSurfSessionLanding';
import SurfSessionsList, { loader as surfSessionsListLoader } from './routes/surf-sessions/SurfSessionsList';
import ViewSurfSession, { loader as surfSessionLoader } from './routes/surf-sessions/ViewSurfSession';

const router = createBrowserRouter([
  { path: '/', element: <NewSurfSession /> },
  { path: '/surf_sessions/:id', loader: surfSessionLoader, element: <ViewSurfSession /> },
  { path: '/surf_sessions', loader: surfSessionsListLoader, element: <SurfSessionsList /> },
  { path: '/login', element: <LogIn /> },
]);

function App() {
  return (
    <div className="h-full">
      <div className="bg-stone-100"></div>
      <div className="bg-stone-200"></div>
      <div className="bg-stone-300"></div>
      <div className="bg-stone-400"></div>
      <div className="bg-stone-500"></div>
      <div className="bg-stone-600"></div>
      <div className="bg-stone-700"></div>
      <div className="bg-stone-800"></div>
      <div className="bg-stone-900"></div>
      <div className="bg-stone-1000"></div>
      <div className="bg-blue-100"></div>
      <div className="bg-blue-200"></div>
      <div className="bg-blue-300"></div>
      <div className="bg-blue-400"></div>
      <div className="bg-blue-500"></div>
      <div className="bg-blue-600"></div>
      <div className="bg-blue-700"></div>
      <div className="bg-blue-800"></div>
      <div className="bg-blue-900"></div>
      <div className="bg-blue-1000"></div>

      <NavBar />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
