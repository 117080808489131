import { round10 } from '../../lib/display';
import { Conditions } from '../models/conditions';

export class TideDecorator {
  constructor(
    private readonly startConditions: Conditions,
    private readonly endConditions: Conditions,
  ) {}

  get hasTide() {
    return this.startConditions.tide && this.endConditions.tide;
  }

  get chipColor() {
    if (this.startConditions.tide.type === 'High') {
      return 'bg-indigo-900';
    } else if (this.startConditions.tide.type === 'Medium') {
      return 'bg-indigo-700';
    } else if (this.startConditions.tide.type === 'Low') {
      return 'bg-indigo-500';
    } else {
      return 'bg-indigo-500';
    }
  }

  get direction() {
    return this.startConditions.tide.height < this.endConditions.tide.height ? 'Rising' : 'Falling';
  }

  get heightRange() {
    return `${round10(this.startConditions.tide.height)} - ${round10(this.endConditions.tide.height)}${this.startConditions.tide.unit.toLowerCase()}`;
  }

  get directionEmoji() {
    return this.direction === 'Rising' ? '📈' : '📉';
  }
}
