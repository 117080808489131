import React, { PropsWithChildren } from 'react';

interface Props {
  title: string;
  header?: string;
}

const ConditionsCard: React.FC<PropsWithChildren<Props>> = ({ title, header, children }) => {
  return (
    <div className="card card-compact bg-base-200 shadow-lg min-w-36">
      <div className="card-body items-center gap-0">
        <div className="text-sm card-title text-primary">{title}</div>
        <div className="text-lg font-semibold">{header}</div>
        {children}
      </div>
    </div>
  );
};

export default ConditionsCard;
